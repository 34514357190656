// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

.login-form {
  position: absolute;
  top: 40%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 320px;
  padding: 30px;
  box-shadow: 0 0 100px rgba(0,0,0,.08);
}

.login-form.reset {
  height: auto;
}

.signup-form {
  position: absolute;
  top: 45%;
  left: 50%;
  margin: -160px 0 0 -160px;
  width: 350px;
  height: 380px;
  padding: 30px;
  box-shadow: 0 0 100px rgba(0,0,0,.08);
}

.login-logo {
  text-align: center;
  cursor: pointer;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-logo img {
  width: 260px;
}

#landing-hero {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 84px;
  height: 80vh;
}

.center-image {
  height: 100%;
  display: flex;
  align-items: center;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.avatar-uploader .ant-upload-list-picture-card-container {
  width: 128px;
  height: 98px;
}

.avatar-uploader .ant-upload-list-item {
  height: 90px;
  width: 128px;
}

form#sponsorForm .ant-upload {
  width: 250px;
  height: 90px;
  margin-bottom: 0;
}

#marina-files .ant-upload-picture-card-wrapper {
    display: block;
    width: inherit;
}

#logo {
  height: 64px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  line-height: 64px;
  white-space: nowrap;
  text-decoration: none;
}

#logo img{
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}

#header {
  background: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  position: relative;
  z-index: 1;
}

.ant-card-head {
  font-weight: 600;
}

#rates .ant-card-grid {
  padding: 10px;
}

#rates .ant-card-grid.active {
  background-color: #fafafa;
}